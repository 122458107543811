let copy = {
    "Header": {
        "Skip": "Skip to main content",
        "Nav": ["Home", "About", "Projects", "Contact"]
    },
    "Home": {
        "PreTitle": "Hi there, my name is",
        "Title": "Donia Amer",
        "Subtitle": "I build (and occasionally design) things for the web",
        "Blurb": "I’m a design oriented software engineer that focuses on building immersive and accessible digital experiences"
    },
    "About": {
        "Title": "About me",
        "Body": [
            "Hey there! I’m Donia, a software engineer based in Toronto, Canada. Fueled by the challenge of solving complex problems and lots of iced coffee, I’m always inspired to build products that start with the user and end with innovation.",
            "After graduating from university and interning at IBM, I built impactful products with the front-end engineering team at Deloitte Digital for a couple years. I've spent some time at KOHO on a mission to democratize the financial industry and InVision working to make real-time collaboration better in this new remote era!",
            "I currently specialize in Front-End! My current toolset includes React, Angular, Typescript, and other various frameworks and libraries related to them. "
        ]
    },
    "Projects": {
        "Title":"Some of the things I’ve built",
        "Cards": [
            {
                "Title": "Project 1",
                "Link": "https://github.com/unixcornthreads",
                "Image": "/images/project1.png",
                "Text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo pulvinar facilisis justo pulvinar facilisis justo.",
                "Tech" : ["JS", "React", "redux"]
            },
            {
                "Title": "Project 2",
                "Link": "https://github.com/unixcornthreads",
                "Image": "/images/project1.png",
                "Text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo pulvinar facilisis justo pulvinar facilisis justo.",
                "Tech" : ["JS", "Angular", "next"]
            },
            {
                "Title": "Personal 3",
                "Link": "https://github.com/unixcornthreads/Personal-Health-Network",
                "Image": "/images/project1.png",
                "Text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo pulvinar facilisis justo pulvinar facilisis justo.",
                "Tech" : ["JS","HTML", "SASS"]
            },
        ]
    },
    "Contact": {
        "Title": "Find me in my  corner of the internet",
        "Subtitle": [
            "or check out my", 
            "resume"
        ],
    }
}

export default copy;
